<template>
  <a-card>
    <a-page-header title="广告优化看板" />
    <a-form-model ref="searchForm" :model="form" v-bind="layout">
      <a-row>
        <a-col :span="5">
          <a-form-model-item label="广告主">
            <a-input v-model="form.advertiserName" placeholder="请输入" style="width: 200px" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="投手">
            <a-select
              v-model="form.operatorIds"
              placeholder="请选择"
              allow-clear
              mode="multiple"
              show-search
              option-filter-prop="children"
              style="width: 200px"
            >
              <a-select-option
                v-for="item in pitcherList"
                :key="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="优化建议">
            <a-select
              v-model="form.advice"
              placeholder="请选择"
              allow-clear
              show-search
              option-filter-prop="children"
              style="width: 200px"
            >
              <a-select-option
                v-for="item in adviceList"
                :key="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="自动规则类型">
            <a-select
              v-model="form.ruleType"
              placeholder="请选择"
              show-search
              allowClear>
              <a-select-option v-for="item in rulesTypeList" :key="item.code" :value="item.code">{{
                  item.msg
                }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="5">
          <a-form-model-item label="规则层级">
            <a-select
              v-model="form.applyObjectType"
              placeholder="请选择"
              allow-clear
              show-search
              option-filter-prop="children"
              style="width: 200px"
            >
              <a-select-option
                v-for="item in applyObjectTypeList"
                :key="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="14"></a-col>
        <a-col :span="5">
          <a-space>
            <a-button @click="handleReset">重置</a-button>
            <a-button type="primary" @click="handleSearch">查询</a-button>
          </a-space>
        </a-col>
      </a-row>

    </a-form-model>
    <div style="padding-left: 30px;color: #9d9c9c;padding-bottom: 25px">只显示系统当前日期的优化建议</div>

    <div class="table-box">
      <a-table
        class="Atables"
        :loading="loading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record, index) => index"
        :pagination='false'
        :scroll="{x: 2000}"
        bordered
      >
        <div
          slot="operation"
          slot-scope="text, record"
          v-if="record.redirect == 1"
        >
          <base-button
            :type="'link'"
            :title="'查看数据明细'"
            @onClickBtn="openDetailDrawer(record)"
          ></base-button>
        </div>
      </a-table>

      <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>
  </a-card>
</template>

<script>
import { cloneDeep, isEmpty, pick } from 'lodash-es';
import moment from 'moment';

export default {
  data() {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
      form: {
        advertiserName: undefined,
        operatorIds: undefined,
        advice: undefined,
        applyObjectType: undefined,
        ruleType: undefined,
      },
      loading: false,
      columns: [
        {
          align: 'center',
          title: '优化建议',
          dataIndex: 'advice',
          width: 120,
        },
        {
          align: 'center',
          title: '自动规则类型',
          dataIndex: 'ruleType',
          width: 150,
        },
        {
          align: 'center',
          title: '广告主',
          dataIndex: 'advertiserName',
          width: 120,
        },
        {
          align: 'center',
          title: '规则层级',
          width: 120,
          dataIndex: 'applyObjectType',
          customRender: (text) => {
            const map = {
              ACCOUNT: '平台账套',
              AD_PROJECT: '项目',
              AD_PROMOTION: '广告',
              AD_MATERIAL: '素材',
            };
            const TEXTNAME = map[text] || '-';
            return TEXTNAME;
          },
        },
        {
          align: 'center',
          title: 'ID',
          dataIndex: 'applyObjectId',
          width: 150,
        },
        {
          align: 'center',
          title: '触发条件',
          dataIndex: 'conditionDesc',
          width: 150,
        },
        {
          align: 'center',
          title: '操作',
          // fixed: 'right',
          scopedSlots: { customRender: 'operation' },
          width: 150,
        },
        {
          align: 'center',
          title: '投手',
          dataIndex: 'operatorName',
          width: 120,
        },
        {
          align: 'left',
          title: '最新更新时间',
          dataIndex: 'updateTime',
        },
      ],
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 100,
        total: 0,
      },
      pitcherList: [],
      rulesTypeList: [],
      adviceList: [],
      applyObjectTypeList: [
        {
          value: 'ACCOUNT',
          label: '平台账套',
        },
        {
          value: 'AD_PROJECT',
          label: '项目',
        },
        {
          value: 'AD_PROMOTION',
          label: '广告',
        },
        {
          value: 'AD_MATERIAL',
          label: '素材',
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.advertiserName) {
      this.$set(this.form, 'advertiserName', this.$route.query.advertiserName);
    } else {
      try {
        const params = JSON.parse(sessionStorage.getItem('_advertising_optimization_board_') || '{}');
        if (!isEmpty(params)) {
          this.form = { ...this.form, ...pick(params, ['advertiserName', 'applyObjectType', 'advice', 'operatorIds', 'ruleType']) };
          // this.form.operatorIds = params.operatorIds.splice(',');
          this.pagination.current = params.page;
          this.pagination.pageSize = params.size;
        }
      } finally {
      }
    }

    this.$nextTick(this.init());
  },
  methods: {
    init() {
      this.getUsersList();
      this.getAdRuleTypeList();
      this.getOptimizeAdviceList();
      this.getList();
    },
    getList() {
      this.loading = true;
      let data = {
        ...this.form,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      sessionStorage.setItem('_advertising_optimization_board_', JSON.stringify(data));
      this.$api.core.JLAdvertManage.getAdAutomateRuleActionLogList(data).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.dataList = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.dataList = [];
          this.pagination.total = 0;
          this.$message.error(`获取列表失败${ res.message }`);
        }
      });
    },
    //获取规则类型数据
    getAdRuleTypeList() {
      this.$api.core.deliverManage.getAdRuleTypeList().then((res) => {
        if (res.code == 200) {
          this.rulesTypeList = res.data;
        } else {
          this.$message.error(`获取规则数据失败，${ res.message }`);
        }
      });
    },
    getOptimizeAdviceList() {
      this.$api.core.JLAdvertManage.getOptimizeAdviceList().then((res) => {
        if (res.code == 200) {
          this.adviceList = res.data;
        } else {
          this.$message.error(`获取优化建议枚举失败，${ res.message }`);
        }
      });
    },
    getUsersList() {
      this.$api.core.pphtManage.getUsersList({ client_id: 'bfz', page_size: 1000 }).then((res) => {
        if (res.code == 200) {
          this.pitcherList = res.data.list;
        } else {
          this.$message.error(`获取投手列表失败，${ res.message }`);
        }
      });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getList();
    },
    handleReset() {
      this.form = cloneDeep(this.$options.data().form);
      this.pagination.current = 1;
      this.pagination.pageSize = 100;
      this.getList();
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getList();
    },
    openDetailDrawer(record) {
      let ROUTERNAME = '';
      switch (record.applyObjectType) {
        case 'ACCOUNT':
          ROUTERNAME = 'platformCreditManage'; //平台账套管理
          break;
        case 'AD_PROJECT':
          ROUTERNAME = 'advertisingProjectManage'; //巨量项目管理
          break;
        case 'AD_PROMOTION':
          ROUTERNAME = 'advertisingManage'; //广告管理
          break;
        case 'AD_MATERIAL':
          ROUTERNAME = 'materialSwitchManage'; //素材开关管理
          // ROUTER = {
          //   name: 'materialSwitchManage', //素材开关管理
          //   query: {
          //     id: record.applyObjectId,
          //   },
          // };
          break;
      }
      let routeUrl = this.$router.resolve({
        path: `/${ ROUTERNAME }`,
        query: {
          id: record.applyObjectId,
          DATA_TIME: [record.startDate + ' 00:00:00', record.endDate + ' 23:59:59'],
        },
      });
      window.open(routeUrl.href, '_blank');
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}

.Atables /deep/ .ant-table {
  font-size: 10px;
}

.Atables /deep/ .ant-table th,
.Atables /deep/ .ant-table td {
  font-size: 10px;
}

</style>
